@import '~plugin_frontend_core/variables';
@import '~plugin_content/pagedesigner/components/common';
@import './components/sizeRefinement';

.page-designer {
    &.homepage {
        .first-region {
            .region-heading {
                padding-top: 15px;
            }
        }
    }
}
