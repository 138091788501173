@import '~plugin_frontend_core/mixins';

.sizeRefinement-title {
    background-color: $styleguide-grey-900;
    box-shadow: -3px 3px 1px $styleguide-grey-500;
    color: $styleguide-white !important;
    display: block;
    font-size: 2rem !important;
    height: 3rem;
    line-height: 1.3 !important;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    width: 250px;
}

.experience-asets-sizeRefinement {
    .refinement-size {
        font-size: 1.5rem;
        height: 2.6rem;
        line-height: 2rem;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev, .slick-next {
        border-color: $styleguide-grey-400;
        border-radius: 3px;
        height: 2.6rem;
        width: 2.6rem;

        @include media-breakpoint-up(md) {
            top: 40%;
        }
    }

    .slick-slide {
        margin: 0 5px;
    }
}
